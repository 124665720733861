export enum AnalyticsNativeEventTypeId {
	SESSION_STARTED = 'SESSION_STARTED',
	BROUGHT_TO_FOREGROUND = 'BROUGHT_TO_FOREGROUND',
	SENT_TO_BACKGROUND = 'SENT_TO_BACKGROUND',
	URL_CHANGED = 'URL_CHANGED',
	API_CALL_ERROR = 'API_CALL_ERROR',
	ACCOUNT_SIGNED_IN = 'ACCOUNT_SIGNED_IN',
	ACCOUNT_SIGNED_OUT = 'ACCOUNT_SIGNED_OUT', // See AnalyticsNativeEventAccountSignedOutSource below
	MYCHART_AUTHENTICATION_SUCCEEDED = 'MYCHART_AUTHENTICATION_SUCCEEDED',
	MYCHART_AUTHENTICATION_FAILED = 'MYCHART_AUTHENTICATION_FAILED',
	PAGE_VIEW_SIGN_IN = 'PAGE_VIEW_SIGN_IN',
	PAGE_VIEW_SIGN_IN_EMAIL = 'PAGE_VIEW_SIGN_IN_EMAIL',
	PAGE_VIEW_HOME = 'PAGE_VIEW_HOME',
	PAGE_VIEW_GROUP_SESSIONS = 'PAGE_VIEW_GROUP_SESSIONS',
	PAGE_VIEW_GROUP_SESSION_DETAIL = 'PAGE_VIEW_GROUP_SESSION_DETAIL',
	PAGE_VIEW_PROVIDERS = 'PAGE_VIEW_PROVIDERS',
	PAGE_VIEW_PROVIDER_APPOINTMENT_CONFIRMATION = 'PAGE_VIEW_PROVIDER_APPOINTMENT_CONFIRMATION',
	PAGE_VIEW_MEDICATION_PRESCRIBER = 'PAGE_VIEW_MEDICATION_PRESCRIBER',
	PAGE_VIEW_TOPIC_CENTER = 'PAGE_VIEW_TOPIC_CENTER',
	PAGE_VIEW_RESOURCE_LIBRARY = 'PAGE_VIEW_RESOURCE_LIBRARY',
	PAGE_VIEW_RESOURCE_LIBRARY_TAG_GROUP = 'PAGE_VIEW_RESOURCE_LIBRARY_TAG_GROUP',
	PAGE_VIEW_RESOURCE_LIBRARY_TAG = 'PAGE_VIEW_RESOURCE_LIBRARY_TAG',
	PAGE_VIEW_RESOURCE_LIBRARY_DETAIL = 'PAGE_VIEW_RESOURCE_LIBRARY_DETAIL',
	PAGE_VIEW_MY_EVENTS = 'PAGE_VIEW_MY_EVENTS',
	PAGE_VIEW_CONTACT_US = 'PAGE_VIEW_CONTACT_US',
	PAGE_VIEW_RESOURCE_NAVIGATOR = 'PAGE_VIEW_RESOURCE_NAVIGATOR',
	PAGE_VIEW_FAQS = 'PAGE_VIEW_FAQS',
	PAGE_VIEW_FAQ_DETAIL = 'PAGE_VIEW_FAQ_DETAIL',
	PAGE_VIEW_INSTITUTION_RESOURCES = 'PAGE_VIEW_INSTITUTION_RESOURCES',
	PAGE_VIEW_INSTITUTION_RESOURCE_GROUP_DETAIL = 'PAGE_VIEW_INSTITUTION_RESOURCE_GROUP_DETAIL',
	PAGE_VIEW_IN_CRISIS = 'PAGE_VIEW_IN_CRISIS',
	PAGE_VIEW_MHIC_PRIORITIES = 'PAGE_VIEW_MHIC_PRIORITIES', // See AnalyticsNativeEventMhicPriorityGroupId below
	PAGE_VIEW_MHIC_ASSIGNED_ORDERS = 'PAGE_VIEW_MHIC_ASSIGNED_ORDERS',
	PAGE_VIEW_MHIC_ASSIGNED_ORDERS_VIEW = 'PAGE_VIEW_MHIC_ASSIGNED_ORDERS_VIEW',
	PAGE_VIEW_MHIC_ALL_ORDERS = 'PAGE_VIEW_MHIC_ALL_ORDERS',
	PAGE_VIEW_MHIC_ORDER_DETAIL = 'PAGE_VIEW_MHIC_ORDER_DETAIL', // See AnalyticsNativeEventMhicOrderDetailSectionId below
	PAGE_VIEW_MHIC_ORDER_ASSESSMENT_RESULTS = 'PAGE_VIEW_MHIC_ORDER_ASSESSMENT_RESULTS',
	PAGE_VIEW_MHIC_ORDER_SEARCH_RESULTS = 'PAGE_VIEW_MHIC_ORDER_SEARCH_RESULTS',
	MHIC_ORDER_AUTOCOMPLETE = 'MHIC_ORDER_AUTOCOMPLETE',
	OVERLAY_VIEW_IN_CRISIS = 'OVERLAY_VIEW_IN_CRISIS', // See AnalyticsNativeEventOverlayViewInCrisisSource below
	CLICKTHROUGH_TOPIC_CENTER = 'CLICKTHROUGH_TOPIC_CENTER', // See AnalyticsNativeEventClickthroughTopicCenterSource below
	CLICKTHROUGH_CONTENT = 'CLICKTHROUGH_CONTENT',
	CLICKTHROUGH_FEATURE = 'CLICKTHROUGH_FEATURE', // See AnalyticsNativeEventClickthroughFeatureSource below
	CLICKTHROUGH_ACCOUNT_SOURCE = 'CLICKTHROUGH_ACCOUNT_SOURCE',
	CLICKTHROUGH_MHIC_RETAKE_ORDER_ASSESSMENT = 'CLICKTHROUGH_MHIC_RETAKE_ORDER_ASSESSMENT',
	CLICKTHROUGH_MHIC_EXPORT_ORDER_ASSESSMENT_RESULTS = 'CLICKTHROUGH_MHIC_EXPORT_ORDER_ASSESSMENT_RESULTS',
	CLICKTHROUGH_MHIC_ORDER_ASSESSMENT_RESULTS = 'CLICKTHROUGH_MHIC_ORDER_ASSESSMENT_RESULTS',

	CLICKTHROUGH_TOPIC_CENTER_GROUP_SESSION = 'CLICKTHROUGH_TOPIC_CENTER_GROUP_SESSION',
	CLICKTHROUGH_TOPIC_CENTER_CONTENT = 'CLICKTHROUGH_TOPIC_CENTER_CONTENT',
	CLICKTHROUGH_TOPIC_CENTER_TAG_GROUP = 'CLICKTHROUGH_TOPIC_CENTER_TAG_GROUP',
	CLICKTHROUGH_TOPIC_CENTER_TAG = 'CLICKTHROUGH_TOPIC_CENTER_TAG',
	CLICKTHROUGH_TOPIC_CENTER_PINBOARD_NOTE_LINK = 'CLICKTHROUGH_TOPIC_CENTER_PINBOARD_NOTE_LINK',

	// Page Builder
	PAGE_VIEW_PAGE = 'PAGE_VIEW_PAGE',
	CLICKTHROUGH_PAGE = 'CLICKTHROUGH_PAGE',
	CLICKTHROUGH_PAGE_GROUP_SESSION = 'CLICKTHROUGH_PAGE_GROUP_SESSION',
	CLICKTHROUGH_PAGE_CONTENT = 'CLICKTHROUGH_PAGE_CONTENT',
	CLICKTHROUGH_PAGE_TAG_GROUP = 'CLICKTHROUGH_PAGE_TAG_GROUP',
	CLICKTHROUGH_PAGE_TAG = 'CLICKTHROUGH_PAGE_TAG',
	CLICKTHROUGH_PAGE_LINK = 'CLICKTHROUGH_PAGE_LINK',
}

export enum AnalyticsNativeEventAccountSignedOutSource {
	CONSENT_FORM = 'CONSENT_FORM',
	PATIENT_HEADER = 'PATIENT_HEADER',
	ADMIN_HEADER = 'ADMIN_HEADER',
	MHIC_HEADER = 'MHIC_HEADER',
	ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED',
	STUDY_ONBOARDING = 'STUDY_ONBOARDING',
}

export enum AnalyticsNativeEventOverlayViewInCrisisSource {
	CONTACT_US = 'CONTACT_US',
	CALL_TO_ACTION = 'CALL_TO_ACTION',
	ERROR_OVERLAY = 'ERROR_OVERLAY',
	PATIENT_HEADER = 'PATIENT_HEADER',
	TRIAGE_RESULTS = 'TRIAGE_RESULTS',
	SCREENING_SESSION = 'SCREENING_SESSION',
}

export enum AnalyticsNativeEventClickthroughTopicCenterSource {
	HOME_FEATURE = 'HOME_FEATURE',
	HOME_SECONDARY_FEATURE = 'HOME_SECONDARY_FEATURE',
	NAV_FEATURE = 'NAV_FEATURE',
	NAV = 'NAV',
}

export enum AnalyticsNativeEventClickthroughFeatureSource {
	HOME = 'HOME',
	NAV = 'NAV',
}

export enum AnalyticsNativeEventMhicPriorityGroupId {
	OUTREACH_REVIEW = 'OUTREACH_REVIEW',
	VOICEMAIL_TASK = 'VOICEMAIL_TASK',
	OUTREACH_FOLLOWUP_NEEDED = 'OUTREACH_FOLLOWUP_NEEDED',
	SCHEDULED_ASSESSMENT = 'SCHEDULED_ASSESSMENT',
	NEED_RESOURCES = 'NEED_RESOURCES',
	SAFETY_PLANNING = 'SAFETY_PLANNING',
}

export enum AnalyticsNativeEventMhicOrderDetailSectionId {
	ORDER_DETAILS = 'ORDER_DETAILS',
	CONTACT_HISTORY = 'CONTACT_HISTORY',
	COMMENTS = 'COMMENTS',
}
